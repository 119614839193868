<template>
  <div id="patient-list">
    <list-template
      v-bind="config"
      :update-component="updateComponent"
      @fetch:data="fetchData"
      @delete:confirm="deleteConfirm"
    >
      <template #create-section>
        <a
          href="javascript:void(0)"
          class="mr-3 mt-1 anchor-link"
          style="font-size: 13px"
          @click="triggerAddPatient"
        >
          Add New Patient</a
        >
      </template>
      <template #custom-cell-ref="{row}">
        <span>
          <router-link
            class="anchor-link"
            :to="{
              name: 'patientOverview',
              params: {
                tab: 'patientOverview',
                id: row.item.id
              }
            }"
          >
            {{ row.item.ref }}
          </router-link>
          <br />
          <b-badge v-if="row.item.discontinuationVisitId">
            Discontinued
          </b-badge>
        </span>
      </template>
      <template #actions="{ row }">
        <dropdown-table-action 
          :items="config.tableAction"
          :row="row"
        />
      </template>
    </list-template>

    <modal-change-physician
      :config="config.modal.setPhysician"
      :role="userData.role"
      :hospitalList="hospitalList"
      :physiciansList="physiciansList"
      @update:selected-physician="setSelectedPhysician"
      @action:fetch-physicians="getPhysicians"
      @action:submit="startUserRegistrationFlow"
      @action:update="patchPhysician"
      @action:close="onChangePhysicianModalClosed"
    />

    <modal-change-opinion
      :config="config.modal.secondOpinion"
      :role="userData.role"
      :hospitalList="hospitalList"
      :physiciansList="physiciansList"
      @action:set-selected-hospital="setSelectedHospital"
      @action:fetch-physicians="getPhysicians"
      @action:get-selected-physician="getSelectedPhysician"
      @action:assign-physician="assignPhysicianForOpinion"
      @action:remove-physician="deletePhysiciansAssigned"
      @action:close="opinionModalClosed"
    />
  </div>
</template>

<script>
// mixins
import crudRequestMixin from "@/mixins/listPages/httpRequestMixins";
// components
import ListTemplate from "@/components/templates/ListTemplate.vue";
// sub components
import ModalChangePhysician from "./SubComponents/ModalChangePhysician.vue";
import ModalChangeOpinion from "./SubComponents/ModalSecondOpinion.vue";
// external library
import moment from "moment";
import Swal from "sweetalert2";
// vuex
import { mapActions } from "vuex";
import { columns } from './column.data';

export default {
  name: "HospitalList",
  mixins: [crudRequestMixin],
  components: {
    ListTemplate,
    ModalChangePhysician,
    ModalChangeOpinion,
  },
  props: {
    userData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const _this = this;

    return {
      config: {
        modal: {
          setPhysician: {
            title: "Set Physicians",
            modalSize: "md",
            show: false,
            state: null,
            selectedPhysician: null,
            selectedPhysicianName: "",
          },
          secondOpinion: {
            title: "Assign Physician For Second Opinion",
            modalSize: "xl",
            show: false,
            state: null,
            hideHeaderClose: true,
            selectedHospital: null,
            selectedPatientId: null,
            currentPhysicianId: null,
            physicianOpinionsList: [],
            physicianOpinionIds: [],
            availablePhysicianForOpinion: [],
          },
        },
        notification: {
          message: "",
          title: "",
          isActive: false,
        },
        loading: false,
        title: "Patients",
        rows: [],
        customizedCells: ['ref'],
        pagination: {},
        columns,
        tableAction: [
          {
            key: 'overview',
            text: 'Overview',
            action: (row) => _this.goToPatientOverview(row.item.id)
          },
          {
            key: 'assessment',
            text: 'New Assessment',
            action: (row) => _this.goToAssessment(row.item.id),
            displayCondition: (row) => _this.canManagePatient(row.item.physicianId),
          },
          {
            key: 'newvisit',
            text: 'New Visit',
            action: (row) => _this.goToNewVisit(row.item.id),
            displayCondition: (row) => _this.canManagePatient(row.item.physicianId),
          },
          // see: https://app.clickup.com/t/8677kz7qe
          // {
          //   key: 'reactivate',
          //   text: 'Reactivate',
          //   action: (row) => _this.confirmReactivate(row.item.id),
          //   displayCondition: (row) => row.item.discontinuationVisitId
          // },
          {
            key: 'download',
            text: 'Download',
            action: (row) => _this.download(row.item.id),
          },
          {
            key: 'change-physician',
            text: 'Change Physician',
            action: (row) => _this.changePhysician({
              physicianId: row.item.physicianId,
              physicianName: row.item.physicianName,
              patientId: row.item.id,
            }),
            displayCondition: (row) => row.item.physicianId && _this.userData.role !== 'PHYSICIAN',
          },
          {
            key: 'second-opinion',
            text: 'Second Opinion',
            action: (row) => _this.setPhysicianForOpinion({
              patientId: row.item.id,
              physiciansOpinionIds: row.item.physiciansOpinionIds,
              physicianId: row.item.physicianId,
            }),
            displayCondition: () => _this.userData.role !== 'PHYSICIAN',
          },
        ],
        loadingText: "Fetching Data...",
        emptyText: "No data found",
        searchPlaceholder: "Search by Patient ID",
        searchBy: ['ref', 'id'],
      },
      physiciansList: [],
      hospitalList: [],
      selectedHospital: null,
      listOf2ndOpinionNames: [],
      updateComponent: 1,
    };
  },
  watch: {
    physiciansList: {
      handler(val) {
        this.config.modal.secondOpinion.availablePhysicianForOpinion =
          this.getPhysiciansForOpinion(val);
      },
      deep: true,
    },
  },
  mounted() {
    const { page, search } = this.$route.query;
    this.fetchData({ 
        paging: { page: Number(page) - 1, size: 100 },
        ...({
          criteria:{
            op:"or",
            of: [
              {
                field:"ref",
                op:"contains",
                value: search
              },
              {
                field:"id",
                op:"contains",
                value: search
              }
            ]
          }
        }),
      }, 
      'mounted'
    );
  },
  methods: {
    ...mapActions({
      setPatientRef: "creation/reqPatientRef",
    }),
    confirmReactivate(id) {
      Swal.fire({
        title: "Confirm",
        text: "Are you sure you want to reactivate this patient ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reactivate",
        customClass: {
          confirmButton: "confirmButton",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.activatePatient(id);
        }
      });
    },
    activatePatient(id) {
      const url = this.$apiEndpoints.reactivate.replace(':id', id);
      this.$requestService.delete(
        url,
        {},
        this.handleReactivateSuccess,
        this.handleReactivateFailed,
      )
    },
    handleReactivateSuccess() {
      this.$vToastify.success("<small>Patient Reactivated</small> <br> <b><i>click this box to dismiss</i></b>");
      this.fetchData();
    },
    handleReactivateFailed(e) {
      if (e.response.status === 409) {
        const message = e.message;
        const beMessage = e.response.data.errors[0].message;
        this.$vToastify.error(`${message} <br> <small> ${beMessage} </small> <br> <br> <b><i>click this box to dismiss</i></b>`);  

        return;
      }

      this.$vToastify.error({
        title: "Oops, something's wrong",
        body: "Please contact administrator"
      });
    },
    canManagePatient(id) {
      return (
        (this.userData.role === "PHYSICIAN" && this.userData.id === id) ||
        this.userData.role !== "PHYSICIAN"
      );
    },
    setSelectedHospital(val) {
      this.config.modal.secondOpinion.selectedHospital = val;
    },
    setSelectedPhysician(val) {
      this.config.modal.setPhysician.selectedPhysician = val;
    },
    // Open modal assignment of second opinion
    setPhysicianForOpinion({ patientId, physicianId, physiciansOpinionIds }) {
      this.config.modal.secondOpinion.show = true;
      if (this.userData.role === "ADMIN") {
        this.getHospital();
      } else if (this.userData.role === "MANAGER") {
        this.config.modal.secondOpinion.selectedHospital =
          this.userData.hospitalId;
      }
      this.config.modal.secondOpinion.selectedPatientId = patientId;
      this.config.modal.secondOpinion.currentPhysicianId = physicianId;

      if (physiciansOpinionIds && physiciansOpinionIds.length) {
        this.getSelectedPhysician(physiciansOpinionIds);
        this.config.modal.secondOpinion.physicianOpinionIds = [
          ...physiciansOpinionIds,
        ];
      }
    },
    // Get previously selected physician for second opinion
    getSelectedPhysician(physicianOpinions = null) {
      const payload = {
        criteria: {
          field: "id",
          op: "in",
          value: `[${physicianOpinions.toString()}]`,
        },
      };
      this.$requestService.post(
        this.$apiEndpoints.users + "/search",
        payload,
        this.handleGetSelectedPhysicianSuccess
      );
    },
    handleGetSelectedPhysicianSuccess(response) {
      this.config.modal.secondOpinion.physicianOpinionsList =
        response.data.content.map((el) => {
          return {
            text: `${el.lastName}, ${el.firstName}`,
            value: el.id,
            hospitalId: el.hospitalId,
            hospitalName: el.transientHospital.name,
          };
        });
    },
    opinionModalClosed() {
      this.config.loading = true;
      this.config.modal.secondOpinion.show = false;
      this.physiciansList =
        this.hospitalList =
        this.config.modal.secondOpinion.physicianOpinionsList =
        this.config.modal.secondOpinion.physicianOpinionIds =
          [];
      this.config.modal.secondOpinion.selectedPatientId =
        this.config.modal.setPhysician.selectedPhysician =
        this.config.modal.setPhysician.selectedPhysicianName =
        this.config.modal.secondOpinion.selectedHospital =
        this.config.modal.secondOpinion.currentPhysicianId =
          null;

      window.location.reload();
    },
    // When click add on assigning physician
    assignPhysicianForOpinion(physician) {
      this.$requestService
        .put(
          this.$apiEndpoints.summaryPatientView +
            `/${this.config.modal.secondOpinion.selectedPatientId}/opinion/${physician.value}`,
          {},
          this.handleAssignPhysicianSuccess(physician)
        )
        .then(() => {
          this.getSelectedPhysician(
            this.config.modal.secondOpinion.physicianOpinionsList.map(
              (el) => el.value
            )
          );
        });
    },
    handleAssignPhysicianSuccess(physician) {
      // Remove from available physician
      let index =
        this.config.modal.secondOpinion.availablePhysicianForOpinion.findIndex(
          (el) => el.value === physician.value
        );
      if (index > -1)
        this.config.modal.secondOpinion.availablePhysicianForOpinion.splice(
          index,
          1
        );

      // push to selected physician for second opinion
      this.config.modal.secondOpinion.physicianOpinionsList.push(physician);
    },
    // Remove assigned physician from filing second opinion on patients
    deletePhysiciansAssigned(physician) {
      this.$requestService.delete(
        this.$apiEndpoints.summaryPatientView +
          `/${this.config.modal.secondOpinion.selectedPatientId}/opinion/${physician.value}`,
        {},
        this.handleRemovePhysicianSuccess(physician)
      );
    },
    handleRemovePhysicianSuccess(physician) {
      let index =
        this.config.modal.secondOpinion.physicianOpinionsList.findIndex(
          (el) => el.value === physician.value
        );

      if (index > -1) {
        this.config.modal.secondOpinion.physicianOpinionsList.splice(index, 1);
      }

      if (
        this.config.modal.secondOpinion.selectedHospital ===
        physician.hospitalId
      ) {
        this.config.modal.secondOpinion.availablePhysicianForOpinion.push(
          physician
        );
      }

      this.getSelectedPhysician(
        this.config.modal.secondOpinion.physicianOpinionsList.map(
          (el) => el.value
        )
      );
    },
    // Get list of physician for second opinion modal
    // removing "Select Physician" from the option
    getPhysiciansForOpinion() {
      return this.physiciansList.filter(
        (el) =>
          !this.config.modal.secondOpinion.physicianOpinionIds.includes(
            el.value
          ) &&
          el.value !== null &&
          el.value !== this.config.modal.secondOpinion.currentPhysicianId
      );
    },
    // trigger change physician modal
    changePhysician({ physicianId, physicianName, patientId }) {
      this.config.modal.setPhysician.selectedPhysician = physicianId;
      this.config.modal.setPhysician.selectedPhysicianName = physicianName;
      this.selectedPatientId = patientId;

      if (this.userData.role === "ADMIN") {
        this.getHospital();
      } else if (this.userData.role === "MANAGER") {
        this.selectedHospital = this.userData.hospitalId;
      }
      this.config.modal.setPhysician.show = true;
      this.config.modal.setPhysician.state = "edit";
    },
    onChangePhysicianModalClosed() {
      this.selectedHospital = null;
      this.config.modal.setPhysician.show = false;
      this.config.modal.setPhysician.state = null;
    },
    // Update selected physician
    patchPhysician(selectedPhysician) {
      this.$requestService.patch(
        this.$apiEndpoints.summaryPatientView + `/${this.selectedPatientId}`,
        {
          physicianId: selectedPhysician,
        },
        this.handlePhysicianUpdateSuccess
      );
    },
    handlePhysicianUpdateSuccess() {
      this.config.modal.setPhysician.show = false;
      this.config.modal.setPhysician.state = null;
      this.config.modal.setPhysician.selectedPhysician =
        this.config.modal.setPhysician.selectedPhysicianName =
        this.selectedPatientId =
          null;

      this.fetchData();
    },
    goToPatientOverview(patientID) {
      this.$router.push({
        name: "patientOverview",
        params: { tab: "patientOverview", id: patientID },
      });
    },
    getHospital() {
      this.$requestService.get(
        this.$apiEndpoints.hospitals,
        {},
        this.handleGetHospitalSuccess
      );
    },
    handleGetHospitalSuccess(response) {
      this.hospitalList = [
        { value: null, text: "Select Hospital" },
        ...response.data.content.map((el) => {
          return { text: el.name, value: el.id };
        }),
      ];
    },
    getPhysicians(hospitalId) {
      const payload = {
        criteria: {
          op: "and",
          field: null,
          value: null,
          of: [
            {
              field: "role",
              op: "is",
              value: "PHYSICIAN",
            },
            {
              field: "hospitalId",
              op: "is",
              value: hospitalId,
            },
          ],
        },
      };

      this.$requestService.post(
        this.$apiEndpoints.users + "/search",
        payload,
        this.handleGetPhysicianSuccess
      );
    },
    handleGetPhysicianSuccess(response) {
      // setup for set physicians options
      this.physiciansList = [
        { value: null, text: "Select Physician" },
        ...response.data.content.map((el) => {
          return {
            text: `${el.lastName}, ${el.firstName}`,
            value: el.id,
            hospitalId: el.hospitalId,
          };
        }),
      ];

      // setup for assigning physicians to file second opinion
      this.config.modal.secondOpinion.availablePhysicianForOpinion =
        this.getPhysiciansForOpinion([
          ...response.data.content.map((el) => {
            return {
              text: `${el.lastName}, ${el.firstName}`,
              value: el.id,
              hospitalId: el.hospitalId,
            };
          }),
        ]);
    },
    // When click add new patient link
    triggerAddPatient() {
      if (this.userData.role === "ADMIN") {
        // Admin has to choose hospital
        this.getHospital();
        this.config.modal.setPhysician.show = true;
        this.config.modal.setPhysician.state = null;
      } else if (this.userData.role === "PHYSICIAN") {
        // Physician will directly use his/her id
        this.config.modal.setPhysician.selectedPhysician = this.userData.id;
        this.startUserRegistrationFlow();
      } else if (this.userData.role === "MANAGER") {
        // Manager has to choose physician based on his hospital
        this.getPhysicians(this.userData.hospitalId);
        this.config.modal.setPhysician.show = true;
        this.config.modal.setPhysician.state = null;
      }
    },
    startUserRegistrationFlow() {
      this.$requestService.post(
        this.$apiEndpoints.startProcessForm +
          `?refKey=${this.config.modal.setPhysician.selectedPhysician}`,
        {},
        this.handleStartProcessSuccess,
        this.handleFailed
      );
    },
    handleStartProcessSuccess(response) {
      let { page, id } = response.data;
      this.$router.push({
        name: 'addPatient',
        params: {
          tab: page,
          id,
          state: 'new-patient'
        }
      });
    },
    handleFetchListSuccess(response) {
      // http request code is in mixins/listPages
      this.config.rows = response.data.content;
      this.config.pagination = response.data.pageable;
      this.config.pagination["totalRows"] = response.data.totalElements;
      this.config.pagination["totalPage"] = response.data.totalPages;

      this.config.rows.forEach(el => {        
        el['2ndOpinionNames'] = this.constructSecondOpinion(el.transientPhysiciansOpinions)
          .replaceAll(',', ', ');

        el.dateOfLastAssessment = el.dateOfLastAssessment
          ? moment(el.dateOfLastAssessment).format("DD/MM/YYYY")
          : "-";

        el.dateOfLastVisit = el.dateOfLastVisit
          ? moment(el.dateOfLastVisit).format("DD/MM/YYYY")
          : "-";
        el["details"] = [];
      });
    },
    constructSecondOpinion(rows) {
      let temp = [];

      if (rows?.length) {
        rows.forEach(el => {
          temp.push(`${el.firstName} ${el.lastName}`);
        });
      }

      return temp.length ? temp.toString() : '-';
    },
    download(id) {
      this.$router.push({
        name: 'patientOverview',
        params: {
          tab: 'evaluationDownload',
          id
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#patient-list {
  height: 60vh !important;
}
.btn {
  border-radius: 0 !important;
  padding: 0.3em 0.3em 0.3em 0.5em !important;
}
.custom-list-group {
  padding: 0.45rem 0.75rem;
}
</style>
